import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { type AxiosError } from 'axios';
import { KeyboardEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import closeEyeIcon from '../assets/eye-off.svg';
import openEyeIcon from '../assets/eye.svg';
import {
  ButtonShowPassword,
  Email,
  Password,
} from '../components/authComponents';
import { EstocaLogo } from '../components/EstocaLogo';
import {
  BackgroundImage,
  Loading,
  useStyles,
} from '../components/styleComponents';
import { AuthContext } from '../contexts/authContext';
import { useDocumentTitle } from '../hooks/use-document-title';
import {
  usePasswordIsVisible,
  useValidEmail,
  useValidPassword,
} from '../hooks/useAuthHooks';
import { api } from '../libs/api';
import { type CognitoError } from '../libs/cognito';
import { Cookie } from '../libs/cookie';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../utils/constants';
import { getUrlQuery } from '../utils/helpers';

type Store = {
  id: string;
  name: string;
  order: number;
  is_test: boolean;
  is_churn: boolean;
  is_saas: boolean;
  legal_name: string;
};

type Group = {
  id: string;
  name: string;
};

type UserType = 'is_saas' | 'is_seller' | 'is_internal' | 'logistic_operator';

type GetMeResponse = {
  id: string;
  email: string;
  user_type: UserType;
  first_name: string;
  last_name: string;
  groups: Group[];
  stores: Store[];
  warehouses: Group[];
};

type GetMePreferenceResponse = {
  codename: string;
  has_permission: boolean;
  id: string;
  value: boolean;
};

export const SignIn = () => {
  const { email, setEmail, emailIsValid } = useValidEmail('');
  const { password, setPassword, passwordIsValid, passwordLabel } =
    useValidPassword('');
  const { passwordIsVisible, setPasswordIsVisible } = usePasswordIsVisible();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0;
  const platformUrl = process.env.REACT_APP_PLATFORM_URL;
  const history = useHistory();

  const authContext = useContext(AuthContext);

  const authenticatePlatform = (
    token: string,
    returnUrl: string,
    postUrl: string
  ) => {
    const form = document.createElement('form');
    const element1 = document.createElement('input');

    form.method = 'POST';
    form.action = `${postUrl}/user/authenticate_token`;

    element1.value = token;
    element1.name = 'token';
    form.appendChild(element1);

    const returnUrlByQuery = getUrlQuery('returnUrl')
      ? String(getUrlQuery('returnUrl'))
      : '';

    const element2 = document.createElement('input');
    element2.value = returnUrlByQuery.includes('seller.estoca')
      ? returnUrlByQuery
      : returnUrl;
    element2.name = 'returnUrl';
    form.appendChild(element2);

    document.body.appendChild(form);

    form.submit();
  };

  const signInClicked = async () => {
    try {
      setLoading(true);

      const requestResponse = await authContext.signInWithEmail(
        email,
        password
      );

      if (requestResponse && platformUrl && platformUrl != null) {
        const token = requestResponse.idToken.jwtToken;
        let redirectUrl = '';
        let postUrl = '';
        let store = '';

        const originUrl = getUrlQuery('origin');

        if (originUrl) {
          redirectUrl = originUrl;
          postUrl = originUrl;

          toast.success(SUCCESS_MESSAGES.signIn);
          return authenticatePlatform(token, redirectUrl, postUrl);
        }

        const { data, status } = await api.get<GetMeResponse>('/profile/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if ([200].includes(status)) {
          const userTypes: UserType[] = [
            'is_saas',
            'is_seller',
            'is_internal',
            'logistic_operator',
          ];
          const isValidUserType = userTypes.includes(data.user_type);


          if (!isValidUserType) {
            redirectUrl = String(process.env.REACT_APP_PLATFORM_URL);
            postUrl = String(process.env.REACT_APP_PLATFORM_URL);
          }

          switch (data.user_type) {
            case 'is_seller':
              redirectUrl = String(process.env.REACT_APP_DEFAULT_RETURN_URL);
              postUrl = String(process.env.REACT_APP_PLATFORM_URL);
              store = data.stores[0].id;
              break;

            case 'is_saas':
              redirectUrl = String(process.env.REACT_APP_PLATFORM_URL);
              postUrl = String(process.env.REACT_APP_PLATFORM_URL);
              break;

            case 'is_internal':
              redirectUrl = String(process.env.REACT_APP_INTERN_LOGIN_URL);
              postUrl = String(process.env.REACT_APP_PLATFORM_URL);
              break;

            case 'logistic_operator':
              redirectUrl = String(process.env.REACT_APP_WMS_URL);
              postUrl = String(process.env.REACT_APP_WMS_URL);
              break;

            default:
              redirectUrl = String(process.env.REACT_APP_PLATFORM_URL);
              break;
          }

          if (data.user_type === 'is_seller') {
            const { data: preferenceData, status: preferenceStatus } =
              await api.get<[GetMePreferenceResponse]>(
                `/profile/store/${store}/preferences?preferences=redirect_store_new_platform`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

            if (
              [200].includes(preferenceStatus) &&
              preferenceData.length &&
              preferenceData[0].value === true
            ) {
              Cookie.set('storeSelectedId', `${store}`, 7);
              const sellerUrl = process.env.REACT_APP_DEFAULT_RETURN_URL ?? '/';
              toast.success(SUCCESS_MESSAGES.signIn);
              window.location.href = sellerUrl;
              return;
            }
          }
          toast.success(SUCCESS_MESSAGES.signIn);
          authenticatePlatform(token, redirectUrl, postUrl);
        }
      }
    } catch (err) {
      const error = err as CognitoError;
      const axiosError = err as AxiosError;
      setLoading(false);

      if (error.code === 'UserNotConfirmedException') {
        history.push('/verify');
        return;
      }

      if (error.code === 'resetPassword') {
        history.push('/new-password');
        return;
      }

      if (axiosError.response && axiosError.response.status === 404) {
        toast.error(ERROR_MESSAGES.userNotFound);
      } else {
        toast.error(ERROR_MESSAGES.signIn);
      }
    }
  };

  const passwordResetClicked = () => history.push('/recovery-password');

  const enterTriggerButton = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const enterButton = document.getElementById('enter-button');
      if (enterButton && !enterButton.hasAttribute('disabled')) {
        enterButton.click();
      }
    }
  };

  useEffect(() => {
    const errorQuery = getUrlQuery('error');
    if (errorQuery === 'UserNotFound') {
      toast.error(ERROR_MESSAGES.userNotFound);
    }
  });

  useDocumentTitle('Login');

  return (
    <Grid className={classes.root} container alignItems="center">
      <BackgroundImage />
      <Grid
        xs={11}
        sm={6}
        lg={6}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        style={{ marginBottom: '100px', margin: '0 auto' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: '700px' }}
        >
          {/* Title */}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <EstocaLogo />
            <Typography
              variant="h5"
              style={{
                fontWeight: 'bold',
                marginTop: '15px',
                marginBottom: '20px',
                textAlign: 'center',
              }}
            >
              Olá! Seja bem-vindo(a) a Plataforma Estoca!
            </Typography>
          </Grid>

          {/* Sign In Form */}
          <Box width="80%" m={2}>
            <Email
              emailIsValid={emailIsValid}
              setEmail={setEmail}
              onKeyUp={enterTriggerButton}
            />
          </Box>
          <Box width="80%" m={1} className="password-container">
            <Password
              label="Senha"
              passwordIsValid={passwordIsValid}
              setPassword={setPassword}
              passwordIsVisible={passwordIsVisible}
              onKeyUp={enterTriggerButton}
              passwordLabel={passwordLabel}
            />
            <img
              src={passwordIsVisible ? openEyeIcon : closeEyeIcon}
              alt="Olho para mostrar conteudo da senha"
              className="show-password-icon"
            />
            <ButtonShowPassword
              passwordIsVisible={passwordIsVisible}
              setPasswordIsVisible={setPasswordIsVisible}
            />
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box onClick={passwordResetClicked} mt={3}>
                <Typography className="forget-password-input" variant="body2">
                  Esqueceu a senha?
                </Typography>
              </Box>
            </Grid>
          </Box>

          {/* Buttons */}
          <Box mt={2} width="80%">
            <Grid
              container
              direction="row"
              justifyContent="center"
              xs={12}
              sm={12}
              lg={12}
            >
              <Box width="100%">
                <Button
                  disabled={isValid || loading}
                  onClick={signInClicked}
                  id="enter-button"
                  className="submit-button"
                >
                  {loading ? <Loading /> : 'Entrar'}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
